import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../components/AuthContext';
import ClipLoader from 'react-spinners/ClipLoader'; // Import a spinner
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import FeedbackPopup from '../components/widgets/FeedbackPopUp';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../styles/Product.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// import required Swiper modules
import { FreeMode, Pagination } from 'swiper/modules';

function Product() {
  const { user } = useAuth();
  const navigate = useNavigate();
  
  const { productSlug } = useParams();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [product, setProduct] = useState(null);
  const [loadingProduct, setLoadingProduct] = useState(true);
  const [quantity, setQuantity] = useState(1); // State for quantity selection, default to 1
  const [grams, setGrams] = useState(0);
  const [size, setSize] = useState();

  useEffect(() => {
    setLoadingProduct(true);
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/product/slug/${productSlug}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        setProduct(response.data.product);
        setLoadingProduct(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setLoadingProduct(false); // Ensure loading state is updated on error
      }
    };

    fetchProduct();
  }, [productSlug]);

  // Set default size to the first available size after product is loaded
  useEffect(() => {
    if (product && product.availableSizes && product.availableSizes.length > 0) {
      setSize(product.availableSizes[0]); // Set size to the first item in the list
    }
  }, [product]);

  const addToBasket = async () => {
    if(user) {
      try {
        var userData = await axios.get(`${process.env.REACT_APP_API_URL}/user/${user.uid}`, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
          },
        });
        userData = userData.data.user;
        // Post the basket item, but use 100 if grams was set to 0 just before the call
        const gramsToUse = product.pricePerKgInCents > 0 && grams === 0 ? 100 : grams;
        let sizeToUse = size || "N/A";
        await axios.post(`${process.env.REACT_APP_API_URL}/basket/add`, { user: userData._id, product: product._id, quantity: quantity, grams: gramsToUse, size: sizeToUse }, {
          headers: {
            'X-API-KEY': process.env.REACT_APP_API_KEY,
            'Content-Type': 'application/json'
          }
        });
        setFeedbackMessage("Item added to basket successfully!"); // Set success message
      } catch (error) {
        console.error('Error submitting form:', error);
        setFeedbackMessage("Failed to add item to basket. Please try again."); // Set error message
      } finally {
        setIsPopupOpen(true); // Open the popup regardless of success or failure
      }
    } else {
      navigate(`/login?redirect=/product/${product.slug}`)
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  return (
    <>
      <Helmet>
        <title>{product ? product.name : 'Product'} | Dal Fiorentino</title>
        <meta name="description" content={product ? product.description : 'Description'} />        
        <meta name="twitter:card" content={`${process.env.REACT_APP_API_URL}/${product ? product.images[0] : 'images/about-1.jpg'}`} />        
        <meta name="twitter:site" content="@dalfiorentino.london" />        
        <meta name="twitter:creator" content="@dalfiorentino.london" />        
        <meta name="twitter:title" content={product ? `${product.name} | Dal Fiorentino` : 'Product | Dal Fiorentino'} />        
        <meta name="twitter:description" content="Dal Fiorentino London is a traditional Italian shop, offering authentic Italian flatbreads from Tuscany. Try our delicious and fresh schiacciate today!" />        
        <meta name="twitter:image" content={`${process.env.REACT_APP_API_URL}/${product ? product.images[0] : 'images/about-1.jpg'}`} />        
        <meta property="og:title" content={product ? `${product.name} | Dal Fiorentino` : 'Product | Dal Fiorentino'} />        
        <meta property="og:description" content="Dal Fiorentino London is a traditional Italian shop, offering authentic Italian flatbreads from Tuscany. Try our delicious and fresh schiacciate today!" />        
        <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/${product ? product.images[0] : 'images/about-1.jpg'}`} />
        <meta property="og:url" content={process.env.REACT_APP_BASE_URL} />
        <meta property="og:site_name" content="Dal Fiorentino | Italian Flatbreads" />
        <meta property="og:locale" content="en_UK" />
        <meta property="og:type" content="website" />

        <meta name="keywords" content="Dal Fiorentino, Italian flatbreads, schiacciate, Tuscan bread, traditional Italian shop, authentic Italian food, schiacciata London, Italian bakery, Italian cuisine London, fresh flatbreads, Tuscany specialties" />
      </Helmet>
      <Navbar />
      <div className="global-container">
        {!loadingProduct && product ? (
          <div className='row'>
            <div className='product-left-column'>
              <Swiper
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  // when window width is >= 1024px
                  1024: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                }}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination]}
              >
                {product.images.map((image) => (
                  <SwiperSlide className='inside-product-swiper-slide'>
                    <img
                      src={process.env.REACT_APP_API_URL + '/' + image}
                      alt="Image"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className='product-right-column'>
              <p style={{ color: 'green' }}>{product.categories[0].name.toUpperCase()}</p>
              <br />
              <h2>{product.name} {product.pricePerKgInCents === 0 ? product.weightInGrams > 1000 ? `| ${product.weightInGrams / 1000}kg` : `${product.weightInGrams}g` : null}</h2>
              <br />
              <h3>£{product.pricePerKgInCents === 0 ? product.priceInCents / 100 : product.pricePerKgInCents / 100 + ' / Kg '}</h3>
              <br />
              {/* If is a "Whole" Product */}
              {product.pricePerKgInCents === 0 ? 
                (
                  <>
                    {product.availableSizes && product.availableSizes.length > 0 && product.availableSizes[0] !== 'N/A' ? (
                        <div className="quantity-input">
                        <select value={size} onChange={(e) => setSize(e.target.value)}>
                          {product.availableSizes.map(size => (
                            <option value={size}>{size}</option>
                          ))}
                        </select>
                      </div>
                    ) : null}
                    <div className='product-quantity-button-wrapper'>
                      <div className="quantity-selector">
                        <div className="quantity-input">
                        <button onClick={() => setQuantity(Math.max(1, quantity - 1))}>-</button>
                        <input type="text" id="quantity" value={quantity} readOnly />
                        <button onClick={() => setQuantity(Math.min(10, quantity + 1))}>+</button>
                        </div>
                      </div>
                      {product.available ? (
                        <button className='button' onClick={() => addToBasket()}>Add to basket</button>
                      ) : (
                        <button className='button' style={{ backgroundColor: 'grey' }}>Sold out</button>
                      )}
                    </div>
                  </>
                /* If is a "Sliced" Product */
                ) : product.pricePerKgInCents > 0 && (
                  <>
                  <br />
                    <div className="quantity-input">
                      <select value={grams} onChange={(e) => setGrams(Number(e.target.value))}>
                        <option value={100}>100g | £{((product.pricePerKgInCents / 100000) * 100).toFixed(2)}</option>
                        <option value={200}>200g | £{((product.pricePerKgInCents / 100000) * 200).toFixed(2)}</option>
                        <option value={300}>300g | £{((product.pricePerKgInCents / 100000) * 300).toFixed(2)}</option>
                        <option value={400}>400g | £{((product.pricePerKgInCents / 100000) * 400).toFixed(2)}</option>
                        <option value={500}>500g | £{((product.pricePerKgInCents / 100000) * 500).toFixed(2)}</option>
                      </select>
                    </div>
                    <div className='product-quantity-button-wrapper'>
                      <div className="quantity-selector">
                        <div className="quantity-input">
                        <button onClick={() => setQuantity(Math.max(1, quantity - 1))}>-</button>
                        <input type="text" id="quantity" value={quantity} readOnly />
                        <button onClick={() => setQuantity(Math.min(10, quantity + 1))}>+</button>
                        </div>
                      </div>
                      <button className='button' onClick={() => addToBasket()}>Add to basket</button>
                    </div>
                  </>
                )
              }
              <br /><br />
              <hr />
              <br /><br />
              <p>{product.name} {product.pricePerKgInCents === 0 ? product.weightInGrams > 1000 ? `| ${product.weightInGrams / 1000}kg` : `${product.weightInGrams}g` : null}</p>
              <br />
              <p>{product.description}</p>
            </div>
          </div>
        ) : (
          <div className="loading-container">
            <ClipLoader size={50} color="#007bff" loading={true} />
          </div>
        )}
      </div>
      <Footer />
      {/* Render the FeedbackPopup */}
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
        showCancel={false} // Only show "Close" button
      />
    </>
  );
}

export default Product;