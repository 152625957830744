import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import FeedbackPopup from '../components/widgets/FeedbackPopUp';
import '../styles/components/ProductCard.css';

const ProductCard = ({ id, name, description, weightInGrams, pricePerKgInCents, availableSizes, available, img, slug }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const addToBasket = async () => {
    if(user) {
      /* If product category is a "Sliced" Product redirect to product page */
      if(pricePerKgInCents > 0 || availableSizes.length > 0) {
        navigate(`/product/${slug}`)
      }
      /* Else add it to basket */
      else {
        try {
          var userData = await axios.get(`${process.env.REACT_APP_API_URL}/user/${user.uid}`, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY,
            },
          });
          userData = userData.data.user;
          await axios.post(`${process.env.REACT_APP_API_URL}/basket/add`, { user: userData._id, product: id, quantity: 1, grams: 0, size: 'N/A' }, {
            headers: {
              'X-API-KEY': process.env.REACT_APP_API_KEY,
              'Content-Type': 'application/json'
            }
          });
          setFeedbackMessage("Item added to basket successfully!"); // Set success message
        } catch (error) {
          console.error('Error submitting form:', error);
          setFeedbackMessage("Failed to add item to basket. Please try again."); // Set error message
        } finally {
          setIsPopupOpen(true); // Open the popup regardless of success or failure
        }
      }
    } else {
      navigate(`/login?redirect=/product/${slug}`)
    }
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setFeedbackMessage(""); // Clear feedback message when closing popup
  };

  return (
    <>
      <div className='product-card'>
        <Link to={`/product/${slug}`}>
          <img src={img} alt='Product Image' />
          <h3>{name + (pricePerKgInCents === 0 ? ` | ${weightInGrams > 999 ? weightInGrams / 1000 + 'kg' : weightInGrams + 'g'}` : '')}</h3>
          <p>{description}</p>
        </Link>
        {available ? (
          <button 
            className='product-card-button'
            onClick={() => addToBasket()} >
              Add to basket
          </button>
        ) : (
          <button 
            className='product-card-button'
            style={{ backgroundColor: 'grey' }} >
              Sold out
          </button>
        )}
      </div>
      {/* Render the FeedbackPopup */}
      <FeedbackPopup
        isOpen={isPopupOpen}
        message={feedbackMessage}
        onClose={closePopup}
        showCancel={false} // Only show "Close" button
      />
    </>
  )
};

export default ProductCard;